
import { mapActions, mapState } from 'vuex'
import store from '../../../../store'

export default{
    
  data: () => ({
        selectedGrupo: { id: 0, name: '', external_login: 0, brands: [], institutions: [] } ,
        selectedIes: { id: 0, name: '', periodos: [], campus: [] },
        selectedPeriodo: null,
        itemsGrupo: [],
        itemsIes: [],
        itemsPeriodo: [],
        checkboxAllBrands: true,
        checkboxAllCampuses: true,
        filteredItemsCampus: [],
        chipsBrands: [],
        chipsCampuses: [],
        show: false,
        message: '',
        color: 'error',
        loading: true,
        grupoVisible: false
    }),
    mounted(){
      
      if(this.$route.query.token){
        
        let token = this.$route.query.token;
        const url = new URL(window.location);
        const newUrl = new URL(url.origin + url.pathname);
        window.history.pushState({}, '', newUrl);
        this.loadAcessoToken(token);

      }else{
        this.loadAcesso();
      }
      
      
    },
    computed: {
      ...mapState('auth', ['user', 'acesso']),

      itemsBrandFiltered(){
        return this.selectedGrupo.brands.filter(i => this.itemsCampus.find(cam => cam.brand_id == i.id));
      },

      isSelectedAnima(){
        return this.isAnima(this.selectedIes);
      },

      itemsCampus(){
        
        let campuses = [];

        this.selectedGrupo.brands.forEach(b => {
          b.campuses.forEach(c => {
              campuses.push(c);
          });
        });

        campuses.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        if(this.isSelectedAnima){
          return campuses;
        }else{
          return campuses.filter(i => i.institution_id == this.selectedIes.id);
        }

        

      },


    },
    methods: { 
      ...mapActions('auth', ['ActionDoLogin', 'ActionLoadAcesso', 'ActionSetUser', 'ActionSetAcesso', 'ActionGetMenus', 'ActionSetParams', 'ActionSetSession', 'ActionSetToken']),
        
        loadAcessoToken(token){
          
          let payload = {
            token: token,
          };

          this.ActionDoLogin(payload)
            .then((res) => {
              this.loadAcesso();
            });

        },
      
        async loadAcesso(){
          
          try{
            
            await this.ActionLoadAcesso()
            .then((res) => {
                    
                this.itemsGrupo = res;

                if(this.itemsGrupo.length == 1){
                    
                    this.grupoVisible = false;
                    this.selectedGrupo = this.itemsGrupo[0];
                    this.changeGrupo(this.selectedGrupo);
                    var listaIes = this.getInstitutions(this.selectedGrupo);
                    
                    if(listaIes.length == 1){
                        
                      var ies = listaIes[0];
                      this.itemsIes = listaIes;
                      this.itemsPeriodo = ies.workTimes;
                      this.selectedIes = ies;
                      this.chipsCampuses = this.itemsCampus;

                      let defaultItems = this.itemsPeriodo.filter((p) => {return p.is_default});

                      if(defaultItems && defaultItems.length >= 1){
                        this.selectedPeriodo = defaultItems[0];
                      }else{
                        this.selectedPeriodo = this.itemsPeriodo[0];
                      }

                      if(this.isSelectedAnima){
                        this.chipsBrands = this.itemsBrandFiltered;
                        this.changeBrand();
                      }


                    }else{
                      if(store.getters['auth/hasAcesso']){
                        this.carregarPeriodo();
                      }
                    }

                  }else{
                    this.grupoVisible = true;
                  }
                    
                  if(store.getters['auth/hasAcesso']){
                    this.carregarPeriodo();
                  }

                  this.loading = false;
                }).finally(() => {

                  if(store.state.auth.user.is_internal && (!this.selectedGrupo || !this.selectedGrupo.id)){
                    this.selectedGrupo = this.itemsGrupo.find(g => g.id == 1);
                    this.changeGrupo(this.selectedGrupo);
                    var listaIes = this.getInstitutions(this.selectedGrupo);
                    this.selectedIes = listaIes[0];
                    this.changeIes(this.selectedIes);
                    
                  }

                });
      
              }catch(err){
                this.erro = err;
              }
        },

        getInstitutions(group){

          var array = [];

          group.brands.forEach((brand) => {
            
              brand.institutions.forEach((ies) => {
                array.push(ies);
              });
            
            });

          return array;
        },

        carregarPeriodo(){
          
          this.selectedGrupo = null;
          this.selectedIes = null;
          this.selectedPeriodo = null;
          this.chipsCampuses = null;
          
          const acesso = store.state.auth.acesso;
         
          var grupo = this.itemsGrupo.find(i => i.id === acesso.grupo.id);
          var listaIes = this.getInstitutions(grupo);
          var ies = listaIes.find(i => i.id === acesso.instituicao.id);
          var periodo = ies.workTimes.find(i => i.id === acesso.periodo.id);
          var campus = acesso.campus;

          this.selectedGrupo = grupo;
          this.itemsIes = listaIes;
          this.selectedIes = ies;
          this.itemsPeriodo = ies.workTimes;
          this.selectedPeriodo = periodo;
          this.chipsCampuses = campus;

          this.filteredItemsCampus = this.itemsCampus;

          if(this.isSelectedAnima){
            let ids = this.chipsCampuses.map(i => i.brand_id);
            this.chipsBrands = this.itemsBrandFiltered.filter(i => ids.includes(i.id));
            this.changeBrand();
            this.chipsCampuses = campus;
          }
          
        },

        async selecionarPeriodo(){
          
          if(!this.validate())
            return;
        
          var acesso = {
                grupo: { 
                  id:             this.selectedGrupo.id, 
                  name:           this.selectedGrupo.name,
                  external_login: this.selectedGrupo.external_login,
                },
                instituicao: { 
                  id:                             this.selectedIes.id,
                  code:                           this.selectedIes.code, 
                  name:                           this.selectedIes.name, 
                  brand:                          this.selectedIes.brand, 
                  enable_activity_time:           this.selectedIes.enable_activity_time,
                  enable_activity_approval_flow:  this.selectedIes.enable_activity_approval_flow,
                  educational_group_id:           this.selectedIes.educational_group_id,
                  calculate_variation:            this.selectedIes.calculate_variation,
                  integrations:                   this.selectedIes.integrations,
                  settings:                       this.selectedIes.settings,  
                },
                periodo: this.selectedPeriodo,
                campus: this.getCampusReduced(this.chipsCampuses),
            };

            this.loading = true;
            this.ActionSetAcesso(acesso);

            var payload = {
              user_id: this.user.id,
              institution_id: acesso.instituicao.id
            }

            var sessionPayload = {
              educational_group_id: this.selectedGrupo.id,
              institution_id: this.selectedIes.id,
              campuses: this.getCampusReduced(this.chipsCampuses),
              work_time_id: this.selectedPeriodo.id,
            }

            await this.ActionSetSession(sessionPayload)
                    .then((r) => {
                      store.state.auth.user.is_master = r.data.user.is_master;
                      this.ActionSetUser(store.state.auth.user);
                      this.ActionSetToken(r.data.token);
                      this.ActionGetMenus(payload)
                      .finally(() => {
                          this.loading = false
                          this.$router.push({ name: 'home', query: { refreshWorkTime: true } });
                      });
                    })
                    .catch((e) => {
                      //
                    })
                    .finally(() => {
                      this.loading = false;
                    })

        },

        getCampusReduced(campuses){

          return campuses.map(o => ({ id: o['id'], name: o['name'], initials: o['initials'], abbreviation: o['abbreviation'], institution_id: o['institution_id'], brand_id: o['brand_id'] }));

        },

        validate () {
          
          var msg = null;
          this.message = '';
          
          var grupo = this.selectedGrupo;
          var ies = this.selectedIes;
          var periodo = this.selectedPeriodo;
          var campus = this.chipsCampuses;

          if(!grupo || !grupo.id || grupo.id == 0){
            msg = "Selecione o Grupo Educacional!";
          }else if(!ies || !ies.id || ies.id == 0){
            msg = "Selecione a Instituição!";
          }else if(!periodo || !periodo.id || periodo.id == 0){
            msg = "Selecione o Período Letivo!";
          }else if(!campus || campus == [] || campus.length == 0){
            msg = "Selecione o Campus!";
          }

          if(msg){
            
            this.message = msg;
            this.show = true;
           
            return false;
          }else
            return true;

        },

        changeGrupo(grupo){
          
          this.selectedIes = {};
          this.selectedPeriodo = {};
          this.chipsCampuses = [];

          this.itemsIes = this.getInstitutions(grupo);

        },

        changeIes(ies){
          
          this.selectedPeriodo = {};
          this.chipsCampuses = [];
          this.itemsPeriodo = ies.workTimes;

          let defaultItems = this.itemsPeriodo.filter((p) => {return p.is_default});

          if(defaultItems && defaultItems.length >= 1){
            this.selectedPeriodo = defaultItems[0];
          }else{
            this.selectedPeriodo = this.itemsPeriodo[0];
          }
          
          this.chipsCampuses = this.itemsCampus;

          if(ies){
            
            if(ies.code == 'anima'){
              this.chipsBrands = this.itemsBrandFiltered;
              this.changeBrand();
            }else{
              
              this.filteredItemsCampus = this.itemsCampus;

            }
            
          }

        },

        selecionarTodosCampus(){
          this.chipsCampuses = this.selectedIes.campus;
        },

        changeBrand(){
          
          if(this.isSelectedAnima){
            let ids = this.chipsBrands.map(i => i.id);
            this.filteredItemsCampus = this.itemsCampus.filter(i => ids.includes(i.brand_id));
            // this.filteredItemsCampus.sort((a,b) => (a.brand_id > b.brand_id) ? 1 : ((b.brand_id > a.brand_id) ? -1 : 0));
            this.chipsCampuses = this.filteredItemsCampus;
          }else{
            this.filteredItemsCampus = this.itemsCampus;
          }
        },

        changeBrandAll(checked){
          
          if(checked){ 
            this.chipsBrands = this.itemsBrandFiltered;
            this.chipsCampuses = this.filteredItemsCampus;
          }else{
            this.chipsBrands = [];
            this.chipsCampuses = [];
          }

          this.changeBrand();
          
        }

      },
}
